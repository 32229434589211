<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>德育之窗</el-breadcrumb-item>
            <el-breadcrumb-item>立德铸魂</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <div class="view-header">
                <el-page-header @back="goBack" :content="this.$route.query.pageTitle"></el-page-header>
            </div>
            <div class="sub-view-container">
                <div class="sub-view-head head_editor">
                    <el-input class="inputC" placeholder="请在这里输入标题（50字以内）" maxlength='50' v-model="title"></el-input>
                </div>
                <div class="diveditor">
                    <!--            <quilleditor-widget ref="quillView" :details="content"></quilleditor-widget>-->
                    <ueditor-wrap v-if="finish" :details="content" ref="quillView" typeStr="school"></ueditor-wrap>
                </div>
            </div>
            <div class="btneditor">
                <el-button type="primary" @click="preserveNews" :loading="btnLoading">保 存</el-button>
            </div>
        </el-card>
    </div>
</template>
<script>
import QuilleditorWidget from '../common/quill_editor_widget.vue';
import UeditorWrap from '../common/ueditor_wrap.vue';
export default {
    data () {
        return {
            title:'',//标题
            content: '',//文本编辑器
            pictureload:'',//图片是否加载完全
            /* source:'',//来源
             author: '',//作者*/
            btnLoading:false,//保存按钮loading
            finish: false
        }
    },
    head: {
        title: '德育之窗',
    },
    components:{
        QuilleditorWidget,
        UeditorWrap
    },
    mounted(){
        if(this.$route.query.id){//编辑
            this.getContent(this.$route.query.id);
        } else {
            this.finish = true
        }
    },
    methods:{
        async getContent(id) {
            const { data: res } = await this.$http.get('/activity/detail', { params: { id: id, type: 7 }})
            this.loading = false;
            if (res.code !== 200) {
                this.finish = true
                this.$message.error(res.message)
            } else {
                this.content = res.result.content
                this.title = res.result.title
                this.finish = true
            }
        },
        //返回上一页
        goBack() {
            this.$router.go(-1);
        },

        //保存
        async preserveNews(){
            if(this.title === '' || this.title == null){
                return this.$message.error('请输入标题！');
            }
            // if(this.content === '' || this.content == null){
            //   return this.$message.error('请输入内容！');
            // }
            if(this.$refs.quillView.content === '' || this.$refs.quillView.content == null){
                return this.$message.error('请输入内容！');
            }
            /*if(this.pictureload !== '-1'){
              return this.$message.error('请稍等，内容中包含图片正在生成！');
            }*/
            this.btnLoading = true;
            let imgReg = /<img.*?(?:>|\/>)/gi;
            let srcReg = /<img\s+src="(.*?)"/ig;
            let str = this.$refs.quillView.content;
            let arr = str.match(imgReg);
            let srcArr = [];
            let pictureUrl = '';
            let resawait = null;
            if(arr != null){
                for (let i = 0; i < arr.length; i++) {
                    while(srcReg.test(arr[i])){
                        srcArr.push(RegExp.$1)
                    }
                }
               // pictureUrl = srcArr[0].replace(/amp;/g,"");
                pictureUrl = srcArr.length > 0 ? srcArr[0].replace(/amp;/g,"") : '';
            }
            console.log('修改：')
            let formData = {
                title: this.title,
                content: this.$refs.quillView.content,
                picture: pictureUrl,
                type: 7
            }
            let result;
            if(this.$route.query.id) {//编辑
                formData.id = this.$route.query.id
                //result = await this.$http.put('/activity/update', formData)
                result = await this.$http({
                    url: '/activity/update',
                    data: formData,
                    method:'put',
                    timeout: 120000
                });
                console.log('编辑：')
            }else {//添加
                // result = await this.$http.post('/activity/add', formData)
                result = await this.$http({
                    url: '/activity/add',
                    data: formData,
                    method:'post',
                    timeout: 120000
                });
            }
            let res = result.data;
            this.btnLoading = false;
            if (res.code !== 200) {
                this.$message.error(res.message)
            } else {
                this.addDialogVisible = false
                this.$message.success(res.message)
                await this.$router.push('/moralComposition')
            }
        },
    }
}
</script>
<style scoped>
.view-header{
    margin:0 -24px;
    padding:0 24px 20px;
    border-bottom:1px solid #E9E9E9;
}
.sub-view-container{width: 100%;margin:0 auto;margin-top:24px;}
.head_editor{
    padding: 12px 0;
    background-color: #F3F3F3;
}
.head_editor .inputC{ font-size: 24px; color: #20243A; border: none; width: 80%; padding: 0 20px; }
.head_editor .inputC::-webkit-input-placeholder { color: #20243A; }
.diveditor{margin-top:16px;height: 390px;}
.view-source{display:flex;align-items:center;padding:8px 24px;background-color:#F3F3F3;color:#666666;}
.view-source .el-input{flex:1;}
.btneditor{margin-top:16px;text-align:center;}
.btneditor .el-button{width:96px;}
</style>
